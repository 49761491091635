import { useState, ChangeEvent, FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useTopic } from "../hooks/useTopic";
import {
  createTutor,
  updateTutor,
  getVoiceListAzure,
  getSampleVoice,
} from "../apis/settingApi";
import LegalDialog from "../components/LegalDialog";
import { ToastMessage } from "./ToastContainer";
import { ErrorCodes } from "../utils/errorCodes";
import { Avatars } from "../utils/enums";
import Lottie from "lottie-react";
import owl from "../assets/owl.json";
import dog from "../assets/dog.json";
import cat from "../assets/cat.json";
import dino from "../assets/dino.json";
import { FaPlay, FaPause } from "react-icons/fa";

interface CreateBotFormProps {
  idToken: string;
  language: string;
  isEdit: boolean;
  initialAge: string;
  initialTopic: string;
  initialAvatarType: string;
  initialAvatarVoice: string;
  initialAvatarName: string;
  initialPassword?: string;
  chatbotId: string;
  onBotCreatedOrUpdated: (data: {
    chatbotId: string;
    link: string;
    azureVoiceType: string;
  }) => void;
  onBack: () => void;
}

const CreateBotForm: FC<CreateBotFormProps> = ({
  idToken,
  language,
  isEdit,
  initialAge,
  initialTopic,
  initialAvatarType,
  initialAvatarVoice,
  initialAvatarName,
  initialPassword = "",
  chatbotId,
  onBotCreatedOrUpdated,
}) => {
  const [age, setAge] = useState<string>(initialAge);
  const {
    topic,
    customTopic,
    predefinedTopics,
    handleTopicChange,
    handleCustomTopicChange,
    setInitialTopic,
  } = useTopic();
  const [avatarName, setAvatarName] = useState<string>(initialAvatarName);
  const [avatarType, setAvatarType] = useState<string>(initialAvatarType);
  const [azureVoiceType, setAvatarVoice] = useState<string>(initialAvatarVoice);
  const [code, setCode] = useState<string>(initialPassword);
  const [isLegalApproved, setIsLegalApproved] = useState<boolean>(isEdit);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [voiceOptions, setVoiceOptions] = useState<{ [key: string]: string }>(
    {}
  );
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const { t, i18n } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const passwordRefs = useRef<(HTMLInputElement | null)[]>([]);
  const initialTopicSetRef = useRef<boolean>(false);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    const fetchVoiceOptions = async () => {
      try {
        const data = await getVoiceListAzure(idToken, language);
        setVoiceOptions(data.voiceList);
      } catch (error) {
        toast.error(t("failedToFetchVoiceOptions"), {
          autoClose: 3000,
          position: "bottom-center",
        });
      }
    };
    fetchVoiceOptions();
  }, [idToken, language, t]);

  const handleAgeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setAge(event.target.value);
  };

  const handleAvatarNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAvatarName(event.target.value);
  };

  const handlePasswordChange =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/[^0-9]/g, "");
      if (value.length <= 1) {
        setCode((prev) => prev.slice(0, index) + value + prev.slice(index + 1));
        if (value && passwordRefs.current[index + 1]) {
          passwordRefs.current[index + 1]?.focus();
        }
      }
    };

  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAvatarType(event.target.value as string);
  };

  const handleVoiceChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setAvatarVoice(event.target.value);
  };

  const handlePlayPauseSampleVoice = async () => {
    if (isPlaying && audio) {
      audio.pause();
      setIsPlaying(false);
    } else {
      try {
        const data = await getSampleVoice(azureVoiceType, language);
        const newAudio = new Audio(`data:audio/wav;base64,${data.audio}`);
        newAudio.onended = () => setIsPlaying(false);
        newAudio.play();
        setAudio(newAudio);
        setIsPlaying(true);
      } catch (error) {
        toast.error(t("failedToPlaySampleVoice"), {
          autoClose: 3000,
          position: "bottom-center",
        });
      }
    }
  };

  const getErrorMessage = (code: ErrorCodes) => {
    return t(code?.toString(), { ns: "errorMessages" });
  };

  const handleCreateTutor = async () => {
    setLoading(true);
    try {
      const data = await createTutor(
        age,
        language,
        topic,
        idToken,
        code,
        avatarName,
        avatarType,
        azureVoiceType
      );
      onBotCreatedOrUpdated({ ...data, azureVoiceType: azureVoiceType });
      toast.success(t("createdSuccessfully"), {
        autoClose: 3000,
        position: "bottom-center",
      });
    } catch (error: any) {
      const errorCode = error.response?.status as ErrorCodes;
      const errorMessage =
        getErrorMessage(errorCode) || error.message || t("An error occurred");
      toast.error(errorMessage, {
        autoClose: 3000,
        position: "bottom-center",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit && initialTopic && !initialTopicSetRef.current) {
      setInitialTopic(initialTopic);
      initialTopicSetRef.current = true;
    }
  }, [isEdit, initialTopic, setInitialTopic]);

  const handleUpdateTutor = async () => {
    setLoading(true);
    try {
      const data = await updateTutor(
        chatbotId,
        idToken,
        age,
        language,
        topic,
        code,
        avatarName,
        avatarType,
        azureVoiceType
      );
      onBotCreatedOrUpdated({ ...data, azureVoiceType });
      toast.success(t("updatedSuccessfully"), {
        autoClose: 3000,
        position: "bottom-center",
      });
    } catch (error: any) {
      const errorCode = error.response?.status as ErrorCodes;
      const errorMessage =
        getErrorMessage(errorCode) || error.message || t("An error occurred");
      toast.error(errorMessage, {
        autoClose: 3000,
        position: "bottom-center",
      });
    } finally {
      setLoading(false);
    }
  };

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const isCreateButtonDisabled =
    !isLegalApproved ||
    age === "" ||
    topic === "" ||
    code.length !== 4 ||
    avatarName === "" ||
    avatarType === "" ||
    azureVoiceType === "";

  return (
    <>
      <div className="ageSettings">
        <label>{t("setAge")}</label>
        <select value={age} onChange={handleAgeChange}>
          <option value="">{t("selectAge")}</option>
          <option value="< 7">{`< 7`}</option>
          <option value="7-10">7-10</option>
          <option value="11-14">11-14</option>
        </select>
      </div>
      <div className="contentSettings">
        <label className="title">{t("selectTopic")}</label>
        <div className="contentCheckbox">
          {predefinedTopics.map((predefinedTopic) => (
            <label key={predefinedTopic} className="topicOption">
              <input
                type="radio"
                value={predefinedTopic}
                checked={topic === predefinedTopic}
                onChange={handleTopicChange}
              />
              <span>
                {t(
                  predefinedTopic
                    .toLowerCase()
                    .replace(": ", "_")
                    .replace(" ", "_")
                )}
              </span>
            </label>
          ))}
          <label className="topicOption">
            <input
              type="radio"
              value="custom"
              checked={!predefinedTopics.includes(topic)}
              onChange={() =>
                handleTopicChange({
                  target: { value: customTopic },
                } as ChangeEvent<HTMLInputElement>)
              }
            />
            <input
              type="text"
              placeholder={t("typeYourTopic")}
              value={customTopic}
              onChange={(e) => {
                handleCustomTopicChange(e);
                handleTopicChange(e);
              }}
              className="customTopicInput"
              disabled={predefinedTopics.includes(topic)}
            />
          </label>
        </div>
      </div>
      <div className="avatarSettings">
        <label>{t("selectAvatarName")}</label>
        <input
          ref={inputRef}
          type="text"
          value={avatarName}
          onChange={handleAvatarNameChange}
          placeholder={t("typeHere")}
        />
      </div>
      <div className="avatarPicker">
        <label>{t("chooseAvatar")}</label>
        <div className="avatarContainer">
          {Object.values(Avatars).map((avatar) => (
            <div key={avatar} className="avatarOption">
              <input
                type="radio"
                value={avatar}
                checked={avatarType === avatar}
                onChange={handleAvatarChange}
              />
              <div className="lottieContainer">
                <Lottie
                  animationData={
                    avatar === Avatars.Owl
                      ? owl
                      : avatar === Avatars.Dog
                        ? dog
                        : avatar === Avatars.cat
                          ? cat
                          : dino
                  }
                  loop={true}
                  style={{ width: 70, height: 70 }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="voicePicker">
        <label>{t("chooseVoiceType")}</label>
        <div className="voiceSelectContainer">
          <select value={azureVoiceType} onChange={handleVoiceChange}>
            <option value="">{t("selectVoice")}</option>
            {Object.entries(voiceOptions).map(([key, value]) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          <button
            type="button"
            className="playButton"
            onClick={handlePlayPauseSampleVoice}
          >
            {isPlaying ? (
              <FaPause color="#ff5700" size={24} />
            ) : (
              <FaPlay color="#ff5700" size={24} />
            )}
          </button>
        </div>
      </div>
      <div className="passwordSettings">
        <label>{t("choosePassword")}</label>
        <div className="passwordInputContainer">
          {[...Array(4)].map((_, i) => (
            <input
              key={i}
              type="text"
              maxLength={1}
              value={code[i] || ""}
              onChange={handlePasswordChange(i)}
              ref={(el) => (passwordRefs.current[i] = el)}
              className="passwordInput"
            />
          ))}
        </div>
      </div>
      <div className="legalApproval">
        <label>
          <input
            type="checkbox"
            checked={isLegalApproved}
            onChange={(e) => setIsLegalApproved(e.target.checked)}
          />{" "}
          <span onClick={toggleDialog} className="legal-link">
            {t("pleaseApproveLegalRights")}
          </span>
        </label>
        {isCreateButtonDisabled && (
          <p className="disclaimer">{t("allFieldsMustBeFilled")}</p>
        )}
      </div>
      <div className="createDiv">
        <button
          className={"createButton"}
          onClick={isEdit ? handleUpdateTutor : handleCreateTutor}
          disabled={isCreateButtonDisabled || isLoading}
        >
          {isLoading ? (
            <div className="spinner"></div>
          ) : isEdit ? (
            t("updateYourAITutor")
          ) : (
            t("createBotButton")
          )}
        </button>
      </div>
      <LegalDialog isOpen={isDialogOpen} onClose={toggleDialog} />
      <ToastMessage />
    </>
  );
};

export default CreateBotForm;
