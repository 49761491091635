import React from "react";
import LangSwitch from "./LangSwitch";
import { IoLogOut } from "react-icons/io5";

import "../styles/Header.css";

interface HeaderProps {
  onLogout?: () => void;
  showLogoutBtn?: boolean;
}

const Header: React.FC<HeaderProps> = ({ onLogout, showLogoutBtn }) => {
  return (
    <header className="header">
      {showLogoutBtn && (
        <button className="logoutButton" onClick={onLogout}>
          <IoLogOut className="logoutIcon" />
        </button>
      )}
      <div className="flex items-center justify-between">
        <div className="logoPlaceholder"></div>
        <div className="flex items-center gap-6 mx-6">
          <LangSwitch locals={["en", "de"]} />
        </div>
      </div>
    </header>
  );
};

export default Header;
