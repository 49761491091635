import React, { FC, RefObject, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ImNewTab } from "react-icons/im";
import { IoMdCopy, IoMdShare } from "react-icons/io";
import { toast } from "react-toastify";
import "../styles/Settings.css";
import { LanguageContext } from "../hooks/LanguageContext";

interface ShareLinkContainerProps {
  chatbotLink: string;
  avatarName?: string;
  avatarCode?: string;
  shareSectionRef: RefObject<HTMLDivElement>;
  isNewBot?: boolean;
}

const ShareLinkContainer: FC<ShareLinkContainerProps> = ({
  chatbotLink,
  shareSectionRef,
  avatarName,
  avatarCode,
  isNewBot,
}) => {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  const addLanguageToLink = (link: string) => {
    const url = new URL(link);
    url.searchParams.set("lang", language);
    return url.toString();
  };

  const updatedChatbotLink = addLanguageToLink(chatbotLink);

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(t("copiedToClipboard"), {
          autoClose: 2000,
          position: "bottom-center",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const shareViaEmail = () => {
    const subject = encodeURIComponent(t("checkOutAiTutor"));
    const body = encodeURIComponent(
      `Hi,\n\n${t("checkOutAiTutor")}: ${updatedChatbotLink}\n\nBest regards,`
    );
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
    window.open(mailtoLink, "_blank");
  };

  return (
    <div ref={shareSectionRef} className="share-section">
      {isNewBot && (
        <div onClick={() => shareViaEmail()} className="linkDisclaimer">
          <p>{t("linkDisclaimer")}</p>
        </div>
      )}
      <div className="copy-section">
        <a href={updatedChatbotLink} className="copy-section-text">
          {updatedChatbotLink}
        </a>
        <button
          className="copy-button"
          onClick={() => copyToClipboard(updatedChatbotLink)}
        >
          <IoMdCopy className="copy-icon" />
        </button>
      </div>
      {isNewBot && (
        <div onClick={() => shareViaEmail()} className="sendCodeTxt">
          <p>
            {t("sendCodeTxt")}{" "}
            <span className="underline">{avatarName ?? "TAMBO"}</span>:{" "}
            {avatarCode ?? "1234"}
          </p>
        </div>
      )}
      <div className="share-buttons">
        <div className="buttons">
          <button onClick={() => copyToClipboard(updatedChatbotLink)}>
            <IoMdCopy className="copy-icon" />
          </button>
          <p>{t("copy-link")}</p>
        </div>
        <div className="buttons">
          <a
            href={updatedChatbotLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>
              <ImNewTab />
            </button>
          </a>
          <p>{t("open-in-tab")}</p>
        </div>
        <div onClick={() => shareViaEmail()} className="buttons">
          <button>
            <IoMdShare />
          </button>
          <p>{t("share")}</p>
        </div>
      </div>
    </div>
  );
};

export default ShareLinkContainer;
